<template>
  <App
    title="Leads"
    @click-left="onNavBarClickLeft"
    left-arrow
    :showFooterTabBar="true"
    activeName="clues"
  >
    <DropdownMenu
      slot="extra"
      active-color="#DA3738"
      class="dropdown-menu"
    >
      <DropdownItem
        v-for="e in tabData"
        :key="e.key"
        v-model="e.value"
        :options="e.list"
        :ref="e.key"
        @change="handlechange(e.value,e.key)"
        @open="handleOpen(e.list)"
      >
        <div
          slot="title"
          v-if="e.key === 'sort'"
        >General</div>
        <div
          slot="title"
          v-if="e.key === 'state'"
        >Status</div>
        <div
          slot="title"
          v-if="e.key === 'score'"
        >Rating</div>
        <div
          slot="title"
          v-if="e.key === 'tags'"
        >客户标签</div>
        <div
          slot="title"
          v-if="e.key === 'source'"
        >Source</div>
        <div
          v-if="e.key === 'tags' || e.key === 'source'"
          class="tags-filter"
        >
          <div
            class="tags-group"
            v-for="group in e.cuList"
            :key="group.key"
          >
            <div class="title" v-if="group.title && (group.children||[]).length">{{group.title}}</div>
            <div class="tags">
              <template v-for="(tag,index) in group.children">
              <button
                v-if="tag.name"
                type="button"
                @click="changeTag(e.key, group.key, tag.key,group.type, group.title)"
                :key="index"
                :class="tag.active ? 'active' : ''"
              >
                {{tag.name}}
              </button>

              </template>
            </div>
          </div>
          <div class="filter-operation">
            <button
              type="button"
              class="reset"
              @click="resetTags(e.key)"
            >Clear</button>
            <button
              type="button"
              class="confirm"
              @click="confirmTags(e.key)"
            >Filter</button>
          </div>
        </div>
      </DropdownItem>
    </DropdownMenu>
    <PullRefresh
      @refresh="onRefresh"
      v-model="loading"
    >
      <List
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        :finished-text="total === 0 ? 'No data!' : 'No more items.!'"
        error-text="Loading failed. Click to retry"
        @load="Onload"
      >
        <CardItem
          v-for="$items in list"
          :key="$items.id"
          :cardInfo="$items"
        />
      </List>
    </PullRefresh>
  </App>
</template>

<script>
import { debounce } from 'lodash-es'
import CardItem from './components/card/index'
import { leadsList, getClueSource2, customerprofile, getEntertainment } from '@/api/chargequery'
import { DropdownMenu, DropdownItem, PullRefresh, List, Toast } from 'vant';
import 'vant/lib/dropdown-menu/style'
import 'vant/lib/dropdown-item/style'
import './index.less';
import dayjs from 'dayjs'
export default {
  name: 'clues',
  components: { DropdownMenu, DropdownItem, PullRefresh, List, CardItem },
  data () {
    return {
      tabData: [
        {
          key: 'sort',
          value: '2',
          list: [
            { text: 'Latest Follow-up Time (Ascending)', value: '1' },
            { text: 'Latest Follow-up Time (Descending)', value: '2' }
          ],
        },
        {
          key: 'state',
          value: 'all',
          list: [],
        },
        {
          key: 'score',
          value: '',
          list: [],
        },
        // {
        //   key: 'tags',
        //   value: '',
        //   cuList: [],
        // },
        // {
        //   key: 'source',
        //   value: '',
        //   cuList: [
        //     {
        //       title: 'Source',
        //       key: 'source',
        //       children: [],
        //       selected: []
        //     },
        //     // {
        //     //   title: '活动信息',
        //     //   key: 'activeInfo',
        //     //   children: [],
        //     //   selected: []
        //     // }
        //   ],
        // }
      ],
      calendarDefault: [],
      list: [],
      clueSource: [],
      hasDate: true,
      loading: true,
      finished: false,
      error: false,
      page: 1,
      size: 20,
      total: 0,
      order: '2', //综合排序
      // status: '', //商机状态
      score: '', //评分
      tag: [], //标签
      camplainCode: '', // 活动信息
      tagsource: [],
      source: '' //来源
    }
  },
  methods: {
    // 加载更多资源
    Onload () {
      this.getData()
    },
    // 下拉刷新
    onRefresh () {
      this.page = 1
      this.finished = false
      this.list = []
      this.total = 0
      this.getData()
    },
    async init () {
      this.page = 1
      this.list = []
      this.total = 0
      await this.getClueSource2()
      await this.getData()
      await this.customerprofilefun()
      await this.getEntertainment()
    },
    // 获取主数据
    initBaseData () {
      const baseData = this.$storage.get('baseData')
      const leadsstatus = baseData.leads_status // 线索状态
      const leadsscore = baseData.leads_score // 线索评分
      console.log('线索评分',leadsscore)
      // 线索状态
      this.tabData[1].list = leadsstatus
      this.tabData[1].list.forEach(item => {
        item.text = item.key
      })
      this.tabData[1].list.unshift({ text: 'All', value: '' })
      // 线索评分
      this.tabData[2].list = leadsscore
      this.tabData[2].list.forEach(item => {
        item.text = item.key
      })
      this.tabData[2].list.unshift({ text: 'All', value: '' })
    },
    //商机状态选择
    handlechange (val, key) {
      if (key === 'state') {
        this.status = val
      }
      if (key === 'sort') {
        this.order = val
      }
      if (key === 'score') {
        this.score = val
      }
      this.onRefresh()
    },
    handleOpen (val) {
      console.log('------val-----------------',val)
      if (val && val.length <= 0) this.initBaseData()
    },
    // 用户标签处理展示
    async customerprofilefun () {
      const res = await customerprofile()
      if (res.code === '000000') {
        this.add = JSON.parse(res.data.template)
        this.add.forEach(item => {
          item.title = item.name
          item.key = item.code
          item.children = item.trees
          item.selected = []
          item.children.forEach(el => {
            el.key = el.code
            el.active = false
          })
        })
        // this.tabData[3].cuList = this.add
      } else {
        Toast(res.msg)
      }
    },
    async getEntertainment () {
      // 活动数据
      const aRes = await getEntertainment({ page: 1, size: 10 })
      if (aRes.code === '000000') {
        if (aRes.data.records) {
          this.activityList = aRes.data.records.map(e => { return { 'key': e.code, 'name': e.name, 'active': false } })
        }
        //this.tabData[3].cuList[1].children = this.activityList
      } else {
        Toast(aRes.msg)
      }
    },
    // 获取线索来源
    async getClueSource2 () {
      const res = await getClueSource2()
      if (res.code === '000000') {
        const k = {};
        res.data.map((e) => {
          k[e.threelevelCode] = e.threelevelName;
        });
        this.clueSource = k;
        // this.tabData[3].cuList[0].children = res.data.map(e => {
        //   return { 'name': e.threelevelName, 'key': e.threelevelCode, 'active': false }
        // })
      } else {
        Toast(res.msg)
      }
    },
    //获取数据
    getData: debounce(async function () {
      if (this.finished) return
      /* Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      }) */
      const params = {
        orderitem: this.order, //综合排序
        status: this.status, //商机状态
        score: this.score, //评分
        // tag: this.tag, //标签
        source: this.source, //来源  
        camplainCode: this.camplainCode // 活动信息
      }
      params.tag = this.tag.length ? this.tag : null
      try {
        const res = await leadsList(
          {
            page: this.page,
            size: this.size
          }, params)
        Toast.clear()
        if (res && res.code === '000000') {
          //添加固定展示
          res.data.records.forEach(el => {
            el.info = [
              {
                infoFollowup: 'Latest follow-up',
                val: el.qbsFollowupTime ? dayjs(el.qbsFollowupTime).format('YYYY/MM/DD') : null
              },
              {
                infoFollowup: 'Follow-up details',
                val: el.qbsFollowupMessage
              }
            ]
            el.threeLevelChannel = this.clueSource[el.threeLevelChannel]
          })
          this.total = res.data.total
          this.list = [...this.list, ...res.data.records]
          if (this.list.length === res.data.total) {
            this.finished = true
          } else {
            this.page++
          }
          this.error = false
          this.loading = false
        } else {
          this.error = true
          this.loading = false
          Toast(res.msg)
        }
      } catch (error) {
        Toast.clear()
        this.error = true
        this.loading = false
      }

    }, 600, { leading: true, trailing: false }),
    // 返回
    onNavBarClickLeft () {
      this.$router.go(-1)
    },
    // 客户标签点击
    changeTag (type, groupKey, tagKey, ctype, title) {
      console.log('r', ctype, 't', title);

      if (type === 'source') {
        this.tabData.forEach(tab => {
          if (tab.key === 'source') {
            console.log(tab.cuList, tagKey)
            tab.cuList.forEach(item => {
              this.camplainCode = ''
              if (item.title === '来源') {
                item.children.forEach(tag1 => {
                  tag1.active = false
                  if (tag1.key === tagKey) {
                    tag1.active = true,
                      this.source = tag1.key
                  }
                })
              }
              if (item.title === '活动信息') {
                item.children.forEach(tag2 => {
                  tag2.active = false
                  if (tag2.key === tagKey) {
                    tag2.active = true
                    this.camplainCode = tag2.key
                  }
                })
              }
            })
          }
        })
      }
      if (type === 'tags') {
        if (ctype === 'multiple' || title === '销售二级顾虑') {
          this.tabData.forEach(tab => {
            if (tab.key === type) {
              tab.cuList.forEach(group => {
                if (group.key === groupKey) {
                  (group.children||[]).forEach(tag => {
                    if (tag.key === tagKey) {
                      tag.active = !tag.active
                    }
                  })
                }
              })
            }
          })
        } else {
          this.tabData.forEach(tab => {
            if (tab.key === type) {
              tab.cuList.forEach(group => {
                if (group.key === groupKey) {
                  (group.children||[]).forEach(tag => {

                    if (tag.key === tagKey) {
                      tag.active = !tag.active
                    } else {
                      tag.active = false
                    }
                  })
                }
              })
            }
          })
        }
      }
    },
    // 过滤cp选择
    filterCp () {
      this.changeCpList = []
      for (let i = 0; i < this.add.length; i++) {
        const item = this.add[i]
        if (item['treesVal'] && item['treesVal'].length > 0) {
          const _item = { ...item }
          _item['trees'] = _item['treesVal']
          delete _item['treesVal']
          this.changeCpList.push(_item)
        }
      }
      // 删除多余参数
      this.changeCpList.forEach(item => {
        delete item.children
        delete item.key
        delete item.selected
        delete item.active
        delete item.title
      })
      return JSON.stringify(this.changeCpList)
    },
    // 确定客户标签 
    confirmTags (key) {
      if (key === 'tags') {
        this.add.forEach((item, index) => {
          this.add[index]['treesVal'] = this.add[index].trees.filter(el => {
            if (el.active) {
              return el
            }
          })
        })
        this.tag = this.filterCp()
        // 关闭DropdownItem
        this.$refs['tags'][0].toggle()
      }
      if (key === 'source') {
        // 关闭DropdownItem
        this.$refs['source'][0].toggle()
      }
      this.onRefresh()
    },
    // 清空客户标签
    resetTags (type) {
      this.tabData.forEach(tab => {
        if (tab.key === type) {
          tab.value = ''
          tab.cuList.forEach(group => {
            group.selected = [](group.children||[]).forEach(tag => {
              tag.active = false
            })
          })
        }
      })
      if (type === 'tags') this.tag = []
      if (type === 'source') this.source = '', this.camplainCode = ''
      // 关闭DropdownItem
      this.$refs[type][0].toggle()
      this.onRefresh()
    },
    // 格式化日期
    formatDate (date) {
      const y = date.getFullYear()
      let m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      let d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      return y + '.' + m + '.' + d
    }
  },
  mounted () {
    this.init()
  }
}
</script>